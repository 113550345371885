<template>
  <!--begin::Aside-->
  <div
    id="kt_aside"
    class="aside aside-hoverable aside-dark"
    data-kt-drawer="true"
    data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
  >
    <!--begin::Brand-->
    <div class="aside-logo flex-column-auto" id="kt_aside_logo" style="padding-left:35%; padding-top: 15px">
      <!--begin::Logo-->
      <router-link to="/dashboard" v-if="asideTheme === 'dark'">
        <img alt="Logo" :src="darkLogo" class="h-55px logo" />
      </router-link>
      <router-link to="/dashboard" v-if="asideTheme === 'light'">
        <img alt="Logo" :src="lightLogo" class="h-55px logo" />
      </router-link>
      <!--end::Logo-->
    </div>
    <!--end::Brand-->

    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
      <KTMenu></KTMenu>
    </div>
    <!--end::Aside menu-->

    <!--
    <div
      class="aside-footer flex-column-auto pt-5 pb-7 px-5"
      id="kt_aside_footer"
    >
      <a
        href="#"
        class="btn btn-custom btn-primary w-100"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-delay-show="8000"
        title="Check out the complete documentation with over 100 components"
      >
        <span class="btn-label">
          {{ t("docsAndComponents") }}
        </span>
        <span class="svg-icon btn-icon svg-icon-2">
          <inline-svg src="/media/icons/duotune/general/gen005.svg" />
        </span>
      </a>
    </div>
    -->
  </div>
  <!--end::Aside-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
// import { ToggleComponent } from "@/presentation/assets/ts/components/_ToggleComponent";
import KTMenu from "@/presentation/layout/aside/Menu.vue";
import { asideTheme } from "@/presentation/helper/LayoutConfig";
import { COMPONENT_NAME } from "@/presentation/constants/Components";

export default defineComponent({
  name: COMPONENT_NAME.KT_ASIDE,
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const { t } = useI18n();

    /*
    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });
    */

    return {
      asideTheme,
      t,
    };
  },
});
</script>
