
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/presentation/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/presentation/assets/ts/components";

import { version } from "@/presentation/helper/Documentation";
import { asideMenuIcons } from "@/presentation/helper/LayoutConfig";
import MainMenuConfig from "@/presentation/config/MainMenuConfig";
import AIMenuConfig from "@/presentation/config/AIMenuConfig";
import { COMPONENT_NAME } from "@/presentation/constants/Components";
import { LocalStorage } from "@/core/storage/LocalStorage";
import { useStore } from "vuex";
import { GetPurchasedProductsModel } from "@/domain/product/get-purchased-products/model/GetPurchasedProductsModel";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { AES256EncryptionService } from "@/infrastructure/encryption/AES256EncryptionService";

const localStorage = new LocalStorage();

export default defineComponent({
  name: COMPONENT_NAME.KT_ASIDE_MENU,
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const swalNotification = new SwalNotification();
    const scrollElRef = ref<null | HTMLElement>(null);
    const roleCode = null;
    const productController = store.state.ControllersModule.productController;

    const purchasedProductsList = ref<GetPurchasedProductsModel[]>([]);
    const isActiveModule = ref<boolean>(false);

    watch(store.getters.buyModuleCount, (newValue) => {
      if (newValue) getPurchasedProducts();
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
    });

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      getPurchasedProducts();
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const hasPermission = (requiredPermission) => {
      if (!requiredPermission) {
        return true;
      }

      const roleCode = AES256EncryptionService.decrypt(
        localStorage.getItem("roleCode")
      ); // kullanıcının permissionları

      return requiredPermission.includes(roleCode);
    };

    // Satın alınan ürünlerin shortCode doğrulaması
    const hasPurchasedProduct = (shortCode: string) => {
      return purchasedProductsList.value.some(
        (product) => product.product.shortCode === shortCode
      );
    };

    const getPurchasedProducts = async () => {
      const purchasedProductListModel: GetPurchasedProductsModel = {
        product: {
          productPackages: [],
        },
      };

      isLoading.value = true;

      productController
        .getPurchasedProducts(purchasedProductListModel)
        .then((response) => {
          if (response.isSuccess) {
            const shortCodesArray: string[] = [];
            response
              .getValue()
              .forEach((product: GetPurchasedProductsModel) => {
                purchasedProductsList.value.push(product);
                isActiveModule.value = true;
                if (product.product.shortCode) {
                  shortCodesArray.push(product.product.shortCode);
                }
              });
              localStorage.setItem("purchasedProducts", shortCodesArray.join(','));
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      AIMenuConfig,
      asideMenuIcons,
      version,
      translate,
      roleCode,
      hasPermission,
      purchasedProductsList,
      isActiveModule,
      hasPurchasedProduct,
      isLoading,
    };
  },
});
