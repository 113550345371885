
import { defineComponent } from "vue";
import { toolbarWidthFluid } from "@/presentation/helper/LayoutConfig";
import { COMPONENT_NAME } from "@/presentation/constants/Components";

export default defineComponent({
  name: COMPONENT_NAME.KT_TOOLBAR,
  props: {
    breadcrumbs: Array,
    title: String,
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
});
