
import { defineComponent, onMounted } from "vue";
import { ScrollTopComponent } from "@/presentation/assets/ts/components/_ScrollTopComponent";
import { COMPONENT_NAME } from "@/presentation/constants/Components";

export default defineComponent({
  name: COMPONENT_NAME.KT_SCROLL_TOP,
  components: {},
  setup() {
    onMounted(() => {
      ScrollTopComponent.reinitialization();
    });
  },
});
