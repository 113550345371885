
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/presentation/store/enums/StoreEnums";

import SwalNotification from "@/presentation/plugins/SwalNotification";
import { COMPONENT_NAME } from "@/presentation/constants/Components";
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
import { ROUTE_PAGES } from "@/domain/constants/RoutePages";

export default defineComponent({
  name: COMPONENT_NAME.KT_USERS_MENU,
  components: {},
  props: {
    email: String,
    name: String,
    surName: String,
    countryCode: String,
    phone: String,
  },
  setup() {
    const { t } = useI18n();
    const swalNotification = new SwalNotification();
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem(LOCAL_STORAGE.LANG)
      ? (localStorage.getItem(LOCAL_STORAGE.LANG) as string)
      : "tr";

    const countries = {
      tr: {
        flag: "/media/flags/turkey.svg",
        name: "Türkçe",
      },
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: ROUTE_PAGES.SIGN_IN }));
    };

    const setLang = (lang) => {
      localStorage.setItem(LOCAL_STORAGE.LANG, lang);
      i18n.locale.value = lang;
      location.reload();
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
