
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/presentation/assets/ts/components";

import HeaderMenuConfig from "@/presentation/config/HeaderMenuConfig";
import { headerMenuIcons } from "@/presentation/helper/LayoutConfig";
import { version } from "@/presentation/helper/Documentation";
import { COMPONENT_NAME } from "@/presentation/constants/Components";

export default defineComponent({
  name: COMPONENT_NAME.KT_MENU,
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      HeaderMenuConfig,
      translate,
      version,
    };
  },
});
