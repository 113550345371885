
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
// import { ToggleComponent } from "@/presentation/assets/ts/components/_ToggleComponent";
import KTMenu from "@/presentation/layout/aside/Menu.vue";
import { asideTheme } from "@/presentation/helper/LayoutConfig";
import { COMPONENT_NAME } from "@/presentation/constants/Components";

export default defineComponent({
  name: COMPONENT_NAME.KT_ASIDE,
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const { t } = useI18n();

    /*
    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });
    */

    return {
      asideTheme,
      t,
    };
  },
});
